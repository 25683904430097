<template>
  <div
    class="SearchResultPages"
    :class="{
      'desktopMaxW-xxs mobOnly:p-12': !inModal,
      'mobOnly:p-12': inModal
    }"
  >
    <div v-if="inModal && results.length" class="type-headline-xl mb-12">
      {{ $t('search.page.othersHeadline') }}
    </div>

    <nuxt-link
      v-for="result in results"
      :key="result.id"
      :to="$manualUrl(result.url)"
      class="block mb-24"
    >
      <div class="type-headline-xs underline mb-8">
        {{ fixCategory(result.contentType) }}
      </div>
      <div class="type-lg searchResultRow" v-html="result._highlightResult.name.value" />
    </nuxt-link>
  </div>
</template>

<script setup lang="ts">
import { AlgoliaPageSearch } from '~/constants/types/algolia';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();
const props = defineProps<{
  search: string,
  inModal: boolean,
}>();

const emit = defineEmits<{
  (e: 'totalHits', input: number): void;
}>();

const results = ref<AlgoliaPageSearch[]>([]);

onMounted(()=> {
  if (props.search.length > 0) {
    nextTick(() => {
      getPages();
    });
  }
});

const getPages = async() => {
  const filter = {
    query: props.search,
  };
  const { data, error } = await useAsyncAlgoliaSearch({
    query: '',
    indexName: globalContent.getAlgoliaIndexPages,
    requestOptions: filter,
    key: JSON.stringify(filter),
  });
  if (data?.value) {
    results.value = data.value.hits;
    emit('totalHits', results.value.length);
  } else {
    // Fails quietly, should this be shown to user?
    console.warn('Couldn\'t get algolia pages');
    console.log(error);
  }
};

const timer = ref<any>(null);

watch(() => props.search, ()=> {
  clearTimeout(timer.value);
  if (props.search.length > 0) {
    timer.value = setTimeout(() => {
      getPages();
    }, 500);
  }
});
const { $t } = useNuxtApp();
const fixCategory = (contentType: string) => {
  if (contentType.startsWith('ArticlePage')) {
    const parts = contentType.split(':');
    if (parts[1]) {
      const artType = parts[1].replace('{','').replace('}','');
      return $t(`articles.keyword.${artType}`);
    } else {
      return $t('articles.keyword.articles');
    }
  }
  return $t(`search.pagetype.${contentType}`);
};

</script>

<style scoped lang="postcss">
.searchResultRow :deep(em) {
    @apply font-medium underline not-italic;
}
</style>
